import logo from './1.png';
import './App.css';
import { Button, Box, Typography } from '@mui/material';
import AppBar from './components/AppBar';
import Hero from './components/Hero';

function App() {
  return (
    <div>
      <AppBar mode='light' />
      <Hero />
      <Box sx={{ bgcolor: 'primary.light' }}>
        <Typography color={'white'}>
      Identify fraud before you send a rate confirmation. <br/>
      Make sure your tech stack compliments your operations, use what's effective for you and get comprehensive training programs developed.<br />
      Ensure your operations team is on the same page and collaborate constantly to streamline and succeed.<br />
      Plugins that help you immediately.
      Identify fraud before you send a rate confirmation. <br/>
      Make sure your tech stack compliments your operations, use what's effective for you and get comprehensive training programs developed.<br />
      Ensure your operations team is on the same page and collaborate constantly to streamline and succeed.<br />
      Identify fraud before you send a rate confirmation. <br/>
      Make sure your tech stack compliments your operations, use what's effective for you and get comprehensive training programs developed.<br />
      Ensure your operations team is on the same page and collaborate constantly to streamline and succeed.<br />
      Identify fraud before you send a rate confirmation. <br/>
      Make sure your tech stack compliments your operations, use what's effective for you and get comprehensive training programs developed.<br />
      Ensure your operations team is on the same page and collaborate constantly to streamline and succeed.<br />
      Identify fraud before you send a rate confirmation. <br/>
      Make sure your tech stack compliments your operations, use what's effective for you and get comprehensive training programs developed.<br />
      Ensure your operations team is on the same page and collaborate constantly to streamline and succeed.<br />
      Identify fraud before you send a rate confirmation. <br/>
      Make sure your tech stack compliments your operations, use what's effective for you and get comprehensive training programs developed.<br />
      Ensure your operations team is on the same page and collaborate constantly to streamline and succeed.<br />
      Identify fraud before you send a rate confirmation. <br/>
      Make sure your tech stack compliments your operations, use what's effective for you and get comprehensive training programs developed.<br />
      Ensure your operations team is on the same page and collaborate constantly to streamline and succeed.<br />
      Identify fraud before you send a rate confirmation. <br/>
      Make sure your tech stack compliments your operations, use what's effective for you and get comprehensive training programs developed.<br />
      Ensure your operations team is on the same page and collaborate constantly to streamline and succeed.<br />
      Identify fraud before you send a rate confirmation. <br/>
      Make sure your tech stack compliments your operations, use what's effective for you and get comprehensive training programs developed.<br />
      Ensure your operations team is on the same page and collaborate constantly to streamline and succeed.<br />
      Identify fraud before you send a rate confirmation. <br/>
      Make sure your tech stack compliments your operations, use what's effective for you and get comprehensive training programs developed.<br />
      Ensure your operations team is on the same page and collaborate constantly to streamline and succeed.<br />
      </Typography>
      </Box>
    </div>
  );
}

export default App;
