import { ThemeOptions, createTheme } from '@mui/material/styles';

export const appTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#0a3042',
      light: '#11506e',
    },
    secondary: {
      main: '#36e961',
      dark: '#23c54b',
      light: '#64ee85',
    },
  },
  typography: {
    fontSize: 13,
  },
  shape: {
    borderRadius: 10,
  }
});