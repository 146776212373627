import * as React from 'react';
import background from '../716.png';
import truck from '../truckhero.png';

import { alpha } from '@mui/material';
import { Box, Button, Container, Link, Stack, TextField, Typography } from '@mui/material';

export default function Hero() {
    return(
        <Box
            id="hero"
            sx={{
                width: '100%',
                backgroundImage: `url(${background})`,
                height: '70vh',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center'
            }}
        >
            <Container
                sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', pt: { xs: 14, sm: 20 }, pb: { xs: 8, sm: 12 }}}
            >
                <Stack spacing={3} useFlexGap sx={{ width: { xs: '100%', sm: '70%' }, alignItems: 'center' }}>
                    <Typography
                        variant="h1"
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', md: 'row' },
                            alignSelf: 'center',
                            textAlign: 'center',
                            fontWeight: 'bold',
                            fontSize: 'clamp(1rem, 10vw, 4rem)',
                            color: 'white'
                        }}
                    >
                        we take logistics seriously.
                    </Typography>
                    <Typography
                        variant="h1"
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', md: 'row' },
                            alignSelf: 'center',
                            alignContent: 'center',
                            textAlign: 'center',
                            fontWeight: 'bold',
                            fontSize: 'clamp(2rem, 10vw, 1rem)',
                            color: 'secondary.main'
                        }}
                    >
                        experts in:&nbsp;
                        <Typography
                        component="span"
                        variant="h1"
                        sx={{
                            fontSize: 'clamp(2rem, 10vw, 1rem)',
                            fontWeight: 'bold',
                            color: 'white'
                        }}
                        >
                        fraud. technology. operations.
                        </Typography>
                    </Typography>
                    <img src={truck} style={{
                        paddingTop: 25,
                        paddingBottom: 25,
                        width: '25vw'
                    }} />
                </Stack>
            </Container>
        </Box>
    );
}