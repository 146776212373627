import * as React from 'react';
import PropTypes from 'prop-types';
import logolight from '../1.png';
import logodark from '../2.png';


import { Box, AppBar as AppBar2, Toolbar, Button, Container, Divider, Typography, MenuItem, Drawer, autocompleteClasses } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const logoStyle = {
    width: '140px',
    height: 'auto',
    cursor: 'pointer',
};

function AppBar({ mode, toggleColorMode }) {
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen) => () => { setOpen(newOpen); };

    const scrollToSection = (sectionId) => {
        const sectionElement = document.getElementById(sectionId);
        const offset = 128;

        if (sectionElement) {
            const targetScroll = sectionElement.offsetTop - offset;

            sectionElement.scrollIntoView({behavior: 'smooth'});
            window.scrollTo({
                top: targetScroll,
                behavior: 'smooth',
            });

            setOpen(false);
        }
    };

    return(
        <div>
            <AppBar2 
                position='fixed'
                sx={{
                    boxShadow: 0,
                    bgcolor: 'transparent',
                    backgroundImage: 'none',
                    mt: 2,
                }}
            >
                <Container maxWidth='lg'>
                    <Toolbar
                        variant='regular'
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            flexShrink: 0,
                            borderRadius: '10px',
                            bgcolor:
                                mode === 'light'
                                ? 'rgba(255, 255, 255, 0.3)'
                                : 'rgba(0, 0, 0, 0.1)',
                            backdropFilter: 'blur(24px)',
                            maxHeight: 40,
                            border: '1px solid',
                            borderColor: 'divider',
                            boxShadow:
                                mode ==='light'
                                ? '0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)'
                                : '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)',
                        }}>
                            <Box sx={{ flexGrow: 1, display :'flex', alignItems: 'center', ml: '-18px', px: 0}}>
                                <img src={logolight} style={logoStyle} />
                                <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                                    <MenuItem sx={{ py: '6px', px: '12px'}}>
                                        <Typography variant='body2' color='text.light'>About</Typography>
                                    </MenuItem>
                                </Box>
                                <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                                    <MenuItem sx={{ py: '6px', px: '12px'}}>
                                        <Typography variant='body2' color='text.light'>Fraud</Typography>
                                    </MenuItem>
                                </Box>
                                <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                                    <MenuItem sx={{ py: '6px', px: '12px'}}>
                                        <Typography variant='body2' color='text.light'>Technology</Typography>
                                    </MenuItem>
                                </Box>
                                <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                                    <MenuItem sx={{ py: '6px', px: '12px'}}>
                                        <Typography variant='body2' color='text.light'>Consulting</Typography>
                                    </MenuItem>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: { xs: 'none', md: 'flex' },
                                    gap: 0.5,
                                    alignItems: 'center',
                                }}
                            >
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    size="small"
                                    component="a"
                                    href="/signin"
                                    target="_blank"
                                >
                                    Sign In
                                </Button>
                            </Box>
                        </Toolbar>

                </Container>
            </AppBar2>
        </div>

    );
}

export default AppBar;